<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {downloadLaudo, update, atualizarBin} from "@/domain/vistoria/services"
import * as Status from "@/domain/vistoria/helpers/types"
import {donwloadFile} from "@/utils/downloadFile"

export default {
  name: 'AcaoVistoria',
  inject: ['container'],
  props: ['vistoria'],
  components: {MenuOptionsItem, UPopover},
  computed: {
    Status () {
      return Status
    }
  },
  data() {
    return {
    }
  },
  methods: {
    editar () {
      this.container.editing = !this.container.editing
    },
    ativarCancelar(status = Status.STATUS_CANCELADO) {
      this.$uloc.dialog({
        title: status === Status.STATUS_CANCELADO ? 'Você está prestes a cancelar uma vistoria' : 'Você está prestes a reabir uma vistoria',
        message: status === Status.STATUS_CANCELADO ? 'Você tem certeza que deseja cancelar esta vistoria ?' : 'Você tem certeza que deseja reabrir esta vistoria ?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Cancelando vistoria'})
        const vistoria = {
          id: this.container.vistoria.id,
          status: status
        }
        update(vistoria, 'PATCH', null)
            .then(response => {
              this.$uloc.loading.hide()
              this.dg(status === Status.STATUS_CANCELADO ? 'Vistoria cancelada.' : 'Vistoria reaberta com sucesso')
              this.container.vistoria = response.data
              this.container.editing = false
              console.log(response.data)
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
      .catch(() => {})
    },
    downloadLaudo () {
      this.$uloc.loading.show({message: 'Gerando laudo...'})
      downloadLaudo(this.vistoria.id)
          .then(response => {
            this.$uloc.loading.hide()
            donwloadFile(response)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    atualizarBin () {
      this.$uloc.loading.show({message: 'Obtendo informações BIN...'})
      atualizarBin(this.vistoria.id)
          .then(response => {
            this.$uloc.loading.hide()
            this.$emit('atualizaBin')
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="atualizarBin" label="Atualizar BIN" close  />
        </ul>
        <ul>
          <menu-options-item @click="editar" :label="container.editing ? 'Cancelar edição' : 'Editar Vistoria'" close  />
        </ul>
<!--        <ul>
          <menu-options-item label="Imprimir" close  />
        </ul>--> <!-- @TODO -->
        <ul>
          <menu-options-item :disabled="container.editing" label="Baixar Laudo" @click="downloadLaudo" close  />
        </ul>
        <ul>
          <menu-options-item v-if="container.vistoria.status != Status.STATUS_CANCELADO" @click="ativarCancelar(Status.STATUS_CANCELADO)" label-class="text-negative" label="Cancelar vistoria" close  /> <!-- @TODO -->
          <menu-options-item v-else @click="ativarCancelar(Status.STATUS_SOLICITADO)" label-class="text-positive" label="Reabrir vistoria" close  /> <!-- @TODO -->
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
