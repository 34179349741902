<template>
  <div v-if="!editing">{{model || '-'}}</div>
  <div v-else>
    <textarea v-if="campo.tipo == 2" class="sl-textarea" v-on:input="$emit('input', $event.target.value)" v-model="model" />
    <input v-else-if="!campo || !Array.isArray(campo.valores) || !campo.valores.length" v-on:input="$emit('input', $event.target.value)" class="sl-input" v-model="model" />
    <list-select v-else input-class="sl-input" emit-only-value ref="select" v-on:input="(v) => $emit('input', v)" :search-data="search" v-model="model" />
  </div>
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
export default {
  name: "CampoInput",
  components: {
    ListSelect
  },
  props: {
    value: {required: true},
    campo: {required: false},
    editing: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    console.log(this.campo)
  },
  data () {
    return {
      model: this.value
    }
  },
  methods: {
    search(terms) {
      console.log(terms)
      return new Promise((resolve, reject) => {
        resolve(this.campo.valores.map((v) => {
          return {
            label: v,
            value: v
          }
        }))
      })
    }
  }
}
</script>
